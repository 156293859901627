<template>
    <div class="course-learning-status">
        <div
            v-if="isProgramInprogress"
            class="course-learning-status__progress-bar">
            <div class="course-learning-status__progress-track bg-black">
                <span
                    v-bind="progressThumbData"
                    class="course-learning-status__progress-thumb bg-orange1" />
            </div>
            <span class="course-learning-status__progress-counter mini-label-text-regular clr-orange">{{ progress }}%</span>
        </div>

        <div
            v-else
            class="course-learning-status__status-block">
            <span class="course-learning-status__icon-wrapper">
                <svg
                    v-if="isProgramFinished"
                    class="course-learning-status__icon"
                    viewBox="0 0 18 18"
                    fill="none">
                    <path
                        d="M8.08222 12.0735C7.87357 12.0735 7.67335 11.9878 7.52814 11.8358L5.54564 9.7539C5.25446 9.44819 5.26671 8.96441 5.57242 8.67323C5.87659 8.38204 6.36033 8.39353 6.65304 8.7L8.03949 10.1552L11.8654 5.48933C12.1329 5.16451 12.6143 5.11481 12.9414 5.38306C13.2686 5.65132 13.3144 6.13206 13.0477 6.45917L8.6723 11.793C8.53321 11.9627 8.32908 12.0635 8.10974 12.0719C8.1021 12.0735 8.09292 12.0735 8.08222 12.0735Z"
                        fill="#4FA246" />
                    <path
                        d="M8.99468 18C6.5903 18 4.32956 17.0646 2.63136 15.3648C-0.878151 11.8545 -0.876658 6.14471 2.63285 2.6352C4.33335 0.935473 6.59332 1.19417e-05 8.99617 1.19417e-05C11.4006 1.19417e-05 13.6613 0.936237 15.3595 2.6352C18.869 6.14395 18.869 11.8538 15.361 15.3633C13.6605 17.0638 11.3991 18 8.99468 18ZM8.99617 1.52854C7.00067 1.52854 5.12441 2.3058 3.71357 3.71587C0.80019 6.62926 0.800226 11.37 3.71208 14.2842C5.12292 15.695 6.99918 16.4715 8.99468 16.4715C10.9917 16.4715 12.868 15.6942 14.2804 14.2826C17.193 11.3692 17.1929 6.62849 14.2788 3.71587C12.8679 2.3058 10.9917 1.52854 8.99617 1.52854Z"
                        fill="#4FA246" />
                </svg>

                <svg
                    v-else
                    class="course-learning-status__icon"
                    viewBox="0 0 20 14"
                    fill="none">
                    <path
                        d="M9.78159 9.42835C9.6734 9.42835 9.56439 9.40671 9.46261 9.36183L0.482486 5.44922C0.18996 5.32179 -2.26971e-05 5.03248 -2.26971e-05 4.7143C-2.26971e-05 4.39613 0.189158 4.10682 0.482486 3.97939L9.46261 0.0666825C9.66617 -0.0222776 9.8994 -0.0222776 10.103 0.0666825L19.0815 3.97939C19.3741 4.10682 19.5624 4.39613 19.5624 4.7143C19.5624 5.03248 19.3749 5.32179 19.0815 5.44922L10.103 9.36183C10.0012 9.40591 9.89139 9.42835 9.78159 9.42835ZM2.80825 4.7143L9.78159 7.75258L16.7549 4.7143L9.78159 1.67602L2.80825 4.7143Z"
                        fill="#999999" />
                    <path
                        d="M9.78184 13.1371C7.35267 13.1371 4.9235 12.4085 2.7564 10.9507C2.5344 10.8008 2.40137 10.5524 2.40137 10.2855V6.01144C2.40137 5.56824 2.75962 5.21 3.20281 5.21C3.64601 5.21 4.00425 5.56824 4.00425 6.01144V9.8495C7.60273 12.0879 11.9609 12.0879 15.5594 9.8495V6.01144C15.5594 5.56824 15.9177 5.21 16.3609 5.21C16.8041 5.21 17.1623 5.56824 17.1623 6.01144V10.2855C17.1623 10.5524 17.0293 10.8016 16.8089 10.9507C14.6426 12.4077 12.211 13.1371 9.78184 13.1371Z"
                        fill="#999999" />
                    <path
                        d="M19.1986 10.9546C18.9782 10.9546 18.7978 10.7751 18.7978 10.5539V4.67615C18.7978 4.45496 18.9782 4.27543 19.1986 4.27543C19.4206 4.27543 19.5993 4.45496 19.5993 4.67615V10.5539C19.5993 10.7751 19.4214 10.9546 19.1986 10.9546Z"
                        fill="#999999" />
                    <path
                        d="M19.1989 11.3552C18.7557 11.3552 18.3975 10.997 18.3975 10.5538V7.74874C18.3975 7.30554 18.7557 6.94729 19.1989 6.94729C19.6421 6.94729 20.0003 7.30554 20.0003 7.74874V10.5538C20.0003 10.997 19.6421 11.3552 19.1989 11.3552Z"
                        fill="#999999" />
                </svg>
            </span>

            <span
                v-bind="statusTextData"
                class="mini-label-text-regular">{{ computedStatusText }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CourseLearningStatus',
    props: {
        // Процент прогресса
        progress: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        computedStatusText() {
            if (this.isProgramFinished) return 'Пройден';
            return 'Доступно для изучения';
        },
        isProgramFinished() {
            return this.progress === 100;
        },
        isProgramInprogress() {
            return this.progress > 0 && this.progress < 100;
        },
        isProgramAvailable() {
            return this.progress === 0;
        },
        progressThumbData() {
            return {
                style: {
                    width: `${this.progress}%`,
                },
            };
        },
        statusTextData() {
            return {
                class: {
                    'clr-gray4': this.isProgramAvailable,
                    'clr-green': this.isProgramFinished,
                },
            };
        },
    },
};
</script>

<style lang="scss">
.course-learning-status {
    display: flex;
    flex-direction: column;

    &__progress-bar {
        display: flex;
        align-items: center;
        column-gap: 28px;
    }

    &__progress-track {
        position: relative;
        flex-grow: 1;
        overflow: hidden;
        height: 6px;
        border-radius: 24px;

        @include respond-min($tablet) {
            height: 8px;
        }
    }

    &__progress-thumb {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 24px;
    }

    &__progress-counter {
        flex-shrink: 0;
    }

    &__status-block {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__icon-wrapper {
        display: flex;
        align-items: center;
        width: 20px;
        height: 20px;
        margin-right: 8px;

        @include respond-min($tablet) {
            width: 24px;
            height: 24px;
        }
    }

    &__icon {
        width: 100%;
        height: 100%;
    }
}

.status-inprogress {
    display: flex;
    align-items: center;
    column-gap: 28px;

    &__progress-bar {
        position: relative;
        flex-grow: 1;
        overflow: hidden;
        height: 6px;
        border-radius: 38px;
    }

    &__progress-thumb {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 24px;
    }

    &__progress-counter {
        flex-shrink: 0;
    }
}

.status-available {
    display: flex;
    align-items: center;
}
</style>
