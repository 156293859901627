import Vue from 'vue';
import App from './App.vue';

import Vue2TouchEvents from 'vue2-touch-events';
import router from './router';
import store from './store';
import VueYandexMetrika from 'vue-yandex-metrika';

import '@/scss/main.scss';

Vue.config.productionTip = false;

Vue.use(VueYandexMetrika, {
    id: 98152721,
    router: router,
    env: process.env.VUE_APP_YM_ENV,
    options: {
        defer: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
    },
});

Vue.use(Vue2TouchEvents, {
    disableClick: false,
    touchClass: '',
    tapTolerance: 10,
    touchHoldTolerance: 400,
    swipeTolerance: 30,
    longTapTimeInterval: 400,
    namespace: 'touch',
});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
