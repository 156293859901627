<template>
    <component
        :is="tagName"
        ref="button"
        class="app-button"
        :class="buttonClasses"
        v-bind="attrs"
        v-on="listeners">
        <slot />
    </component>
</template>

<script>
export default {
    name: 'AppButton',
    inheritAttrs: false,
    props: {
        // Состояние disabled
        disabled: {
            type: Boolean,
            default: false,
        },
        // Позволяет переопределить тег компонента
        tag: {
            type: String,
            default: 'button',
        },
        // При наличии атрибута to компонент становится <router-link>
        to: {
            type: [Object, String],
            default: null,
        },
    },
    computed: {
        isRouterLink() {
            return Boolean(this.to);
        },
        isLink() {
            return typeof this.$attrs.href !== 'undefined';
        },
        isButton() {
            return this.tagName === 'button';
        },
        tagName() {
            let tag = this.tag || 'button';
            if (this.isRouterLink) {
                tag = 'router-link';
            } else if (this.isLink) {
                tag = 'a';
            }
            return tag;
        },
        isButtonDisabled() {
            return Boolean(this.disabled);
        },
        buttonClasses() {
            return [
                {
                    disabled: this.isButtonDisabled,
                },
            ];
        },
        attrs() {
            const attrs = { ...this.$attrs };
            if (this.isRouterLink) {
                attrs.to = this.to;
                attrs.tag = this.tag;
            } else if (this.isButton) {
                attrs.type = attrs.type || 'button';
            }

            if (this.isButtonDisabled) {
                if (this.isButton) {
                    attrs.disabled = 'disabled';
                }
            }

            return attrs;
        },
        listeners() {
            return {
                ...this.$listeners,
                click: this.buttonClickHandler,
                keyup: this.buttonKeyupHandler,
            };
        },
    },
    methods: {
        buttonBlur() {
            this.$refs.button?.blur();
        },
        buttonClickHandler(event) {
            this.buttonBlur();
            this.$emit('click', event);
        },
        buttonKeyupHandler(event) {
            if (event.key === 'Escape' || event.key === 'Esc') {
                this.buttonBlur();
            }

            this.$emit('keyup', event);
        },
    },
};
</script>

<style lang="scss">
.app-button {
    position: relative;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    color: inherit;
    outline: none;
    font-family: inherit;
    user-select: none;
    transition: 0.1s ease-in-out;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    .grabbing & {
        cursor: grabbing;
    }

    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        inset: -0.4em;
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;

        & > * {
            pointer-events: none;
        }
    }

    &:not(.disabled):active {
        opacity: 0.6;
    }
}
</style>
