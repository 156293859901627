<template>
    <transition
        name="fade"
        mode="out-in">
        <div class="app-fullscreen-popup">
            <article class="app-fullscreen-popup__main">
                <AppButton
                    v-if="closeButtonAvailable"
                    class="app-fullscreen-popup__close-button"
                    @click="$emit('closePopup')">
                    <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.3527 0.420632C16.0743 0.140659 15.7086 3.21325e-08 15.343 0C14.9775 -3.21325e-08 14.6118 0.139223 14.3333 0.420631L8.38536 6.39827L2.43817 0.421494C1.87979 -0.139887 0.977159 -0.139887 0.418782 0.421494C-0.139594 0.982874 -0.139594 1.89171 0.418782 2.45165L6.32388 8.38563L0.418782 14.3196C-0.139594 14.8795 -0.139594 15.7884 0.418782 16.3498C0.697257 16.6297 1.06289 16.7704 1.42847 16.7704C1.79406 16.7704 2.15969 16.6312 2.43817 16.3498L8.38615 10.3721L14.3333 16.3489C14.8917 16.9103 15.7944 16.9103 16.3527 16.3489C16.9111 15.7875 16.9111 14.8787 16.3527 14.3187L10.4476 8.38476L16.3527 2.45079C16.9111 1.89085 16.9111 0.982012 16.3527 0.420632ZM8.38615 10.3721L9.35278 9.40066C9.62126 9.13218 9.77116 8.7661 9.77116 8.38563C9.77116 8.00515 9.62126 7.64043 9.35278 7.3705L8.38536 6.39827L7.41873 7.36973C7.15025 7.63821 7.00035 8.00429 7.00035 8.38476C7.00035 8.76524 7.15025 9.12997 7.41873 9.39989L8.38615 10.3721Z"
                            fill="#828282" />
                        <path
                            d="M9.77116 8.38563C9.77116 8.00515 9.62126 7.64043 9.35278 7.3705L8.38536 6.39827L7.41873 7.36973C7.15025 7.63821 7.00035 8.00429 7.00035 8.38476C7.00035 8.76524 7.15025 9.12997 7.41873 9.39989L8.38615 10.3721L9.35278 9.40066C9.62126 9.13218 9.77116 8.7661 9.77116 8.38563Z"
                            fill="#828282" />
                    </svg>
                </AppButton>

                <slot>
                    PLACE YOUR CONTENT HERE
                </slot>
            </article>
        </div>
    </transition>
</template>
<script>
import AppButton from '@/components/AppButton.vue';

export default {
    name: 'AppFullscreenPopup',
    components: {
        AppButton,
    },
    props: {
        closeButtonAvailable: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        buttonClickHandler() {
            this.$emit('button:click');
        },
    },
    mounted() {
        const body = document.querySelector('body');
        const html = document.querySelector('html');
        body.classList.add('scroll-disabled');
        html.classList.add('scroll-disabled');
    },
    beforeDestroy() {
        const body = document.querySelector('body');
        const html = document.querySelector('html');
        body.classList.remove('scroll-disabled');
        html.classList.remove('scroll-disabled');
    },
};
</script>

<style lang="scss">
.app-fullscreen-popup {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background-color: rgba(#000000, 0.7);

    &__main {
        position: relative;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        max-width: 400px;
        max-height: 100%;
        padding: 30px 24px;
        border-radius: 24px;
        background-color: var(--white);

        @include respond-min($tablet) {
            max-width: 672px;
            padding: 48px 36px;
        }
    }

    &__close-button {
        position: absolute;
        top: 24px;
        right: 24px;
        align-self: flex-end;
    }
}
</style>
