<template>
    <article class="article-wrapper">
        <div
            class="course-slide__like"
            @click="submitLike">
            <svg
                viewBox="0 0 512 512"
                style="enable-background: new 0 0 512 512;">
                <path
                    :fill="colorLike"
                    d="M375.11,43.767c-54.778,0-101.432,34.766-119.11,83.436c-17.678-48.67-64.332-83.436-119.11-83.436
                    c-69.97,0-126.69,56.721-126.69,126.69s84.802,151.608,126.69,190.577C166.354,388.445,256,468.233,256,468.233
                    s89.646-79.788,119.11-107.2c41.888-38.968,126.69-120.608,126.69-190.576S445.08,43.767,375.11,43.767z" />
                <g>
                    <path
                        style="fill: #000000;"
                        d="M256,478.432c-2.423,0-4.848-0.86-6.78-2.58c-0.898-0.799-90.068-80.177-119.277-107.35
                        C74.159,316.606,0,239.616,0,170.457c0-75.481,61.408-136.89,136.89-136.89c49.671,0,95.091,27.134,119.11,69.393
                        c24.019-42.259,69.439-69.393,119.11-69.393c75.481,0,136.89,61.408,136.89,136.89c0,69.159-74.159,146.148-129.942,198.044
                        c-29.21,27.173-118.379,106.552-119.277,107.35C260.848,477.573,258.423,478.432,256,478.432z M136.89,53.966
                        c-64.234,0-116.491,52.258-116.491,116.491c0,27.938,15.306,61.803,45.493,100.656c27.856,35.853,62.958,68.509,77.946,82.453
                        c24.326,22.631,90.709,81.882,112.164,101.007c21.455-19.125,87.838-78.376,112.164-101.007
                        c14.989-13.944,50.09-46.599,77.945-82.453c30.186-38.853,45.492-72.718,45.492-100.656c0-64.234-52.258-116.491-116.491-116.491
                        c-48.842,0-92.857,30.831-109.524,76.718c-1.465,4.033-5.295,6.717-9.586,6.717c-4.291,0-8.122-2.684-9.586-6.717
                        C229.746,84.797,185.732,53.966,136.89,53.966z" />
                </g>
            </svg>
        </div>

        <div class="course-slide__content">
            <section class="course-slide__image-container">
                <AppImageResolver
                    folderName="home-page"
                    :imageName="courseName"
                    :imagePath="courseImage"
                    position="center"
                    class="course-slide__image" />
            </section>

            <section class="course-slide__text-container">
                <h2 class="course-slide__title h2-extra-bold">
                    {{ courseTitle }}
                </h2>

                <CourseLearningStatus
                    class="course-slide__learning-status"
                    :progress="courseProgress" />

                <p class="course-slide__description text-regular">
                    {{ courseDescription }}
                </p>
            </section>
        </div>

        <footer class="course-slide__footer">
            <AppButton
                class="course-slide__slide-button button"
                tag="a"
                v-bind="slideButtonData">
                {{ slideButtonText }}
            </AppButton>
        </footer>
    </article>
</template>

<script>
import AppButton from '@/components/AppButton.vue';
import AppImageResolver from '@/components/AppImageResolver.vue';
import CourseLearningStatus from '@/components/CourseLearningStatus.vue';
import { mapActions } from 'vuex';

export default {
    name: 'CourseTile',
    components: {
        AppButton,
        AppImageResolver,
        CourseLearningStatus,
    },
    props: {
        courseData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            isLike: false,
        };
    },
    computed: {
        courseImage() {
            return this.courseData?.image;
        },
        courseTitle() {
            return this.courseData?.title;
        },
        courseDescription() {
            return this.courseData?.description;
        },
        courseProgress() {
            return this.courseData?.progress;
        },
        courseLiked() {
            return this.courseData?.isLiked;
        },
        colorLike() {
            return this.isLike ? '#F15B6C' : 'rgba(255, 255, 255, 0)';
        },
        slideButtonText() {
            if (this.isProgramFinished) return 'Повторить';
            else if (this.isProgramInprogress) return 'Продолжить';

            return 'Начать курс';
        },
        courseName() {
            return this.courseData?.name;
        },
        isProgramFinished() {
            return this.courseProgress === 100;
        },
        isProgramInprogress() {
            return this.courseProgress > 0 && this.courseProgress < 100;
        },
        isProgramAvailable() {
            return this.courseProgress === 0;
        },
        slideButtonData() {
            return {
                to: {
                    name: 'course',
                    params: {
                        courseId: this.courseData?.id,
                    },
                },
            };
        },
    },
    methods: {
        ...mapActions('structure', ['fetchLike', 'fetchCourse', 'deleteLike']),
        submitLike() {
            if (this.likeTimer) {
                clearTimeout(this.likeTimer);
            }

            this.likeTimer = setTimeout(async() => {
                try {
                    if (this.isLike) {
                        this.isLike = !this.isLike;
                        await this.deleteLike({ itemId: this.courseData.id });
                    } else {
                        this.isLike = !this.isLike;
                        await this.fetchLike({ itemId: this.courseData.id });
                    }
                } catch (error) {
                    console.error('Error in submitLike:', error);
                    this.isLike = !this.isLike;
                }
            }, 1000);
        },
    },
    created() {
        this.isLike = this.courseLiked;
    },
};
</script>

<style lang="scss">
.article-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.course-slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 24px;
    border-radius: 24px;
    background-color: var(--white);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

    @include respond-min($tablet) {
        padding-top: 64px;
        padding-right: 36px;
        padding-bottom: 40px;
        padding-left: 36px;
        box-shadow: 0 7px 27px rgba(0, 0, 0, 0.1);
    }

    &__content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        row-gap: 22px;

        @include respond-min($tablet) {
            row-gap: 56px;
        }
    }

    &__image-container {
        position: relative;
        display: flex;
        flex-basis: 150px;
        align-items: flex-end;
        justify-content: center;
        max-width: 100%;

        @include respond-min($tablet) {
            flex-basis: 240px;
            align-items: center;
        }
    }

    &__like {
        position: absolute;
        top: 2%;
        right: 10%;
        cursor: pointer;

        @include respond-min($tablet) {
            top: 2%;
            right: 2%;
        }

        svg {
            width: 15px;
            height: 15px;

            @include respond-min($tablet) {
                width: 25px;
                height: 25px;
            }
        }
    }

    &__image {
        max-height: 100%;
    }

    &__title {
        padding-right: 0;
        text-align: center;
    }

    &__learning-status {
        margin-top: 12px;

        @include respond-min($tablet) {
            margin-top: 24px;
        }
    }

    &__description {
        margin-top: 20px;

        @include respond-min($tablet) {
            margin-top: 24px;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        margin-top: 30px;

        @include respond-min($tablet) {
            margin-top: 36px;
        }
    }

    &__counter-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        margin-bottom: 36px;
    }

    &__counter-text {
        margin-top: 4px;
    }

    &__slide-button {
        display: flex;
        align-items: center;
        align-self: center;
        justify-content: center;
        width: 100%;
        padding: 18px 36px;
        border-radius: 38px;
        background-color: var(--black);

        &.disabled {
            background-color: var(--gray2);
        }
    }
}
</style>
