import Vue from 'vue';
import Vuex from 'vuex';
import structure from '@/store/modules/structure.js';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        structure,
    },
});
