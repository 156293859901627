<template>
    <div class="home-page-notifications">
        <NotificationCard
            v-for="(item, i) in notifications"
            :key="i"
            class="home-page-notifications__notification-card bg-white"
            v-bind="getNotificationData(item)">
            <template #title>
                {{ item.title }}
            </template>

            <template #description>
                {{ item.description }}
            </template>

            <template #buttonText>
                {{ item.buttonText }}
            </template>
        </NotificationCard>
    </div>
</template>

<script>
import NotificationCard from '@/components/NotificationCard.vue';
const NOTIFICATIONS = [
    {
        type: 'incomplete',
        read: false,
        recievedAt: '8 февраля',
        title: 'Продолжайте обучение',
        description: 'У вас есть незавершенный урок, хотите продолжить?',
        buttonText: 'Продолжить урок',
    },
];
export default {
    name: 'HomePageNotifications',
    components: {
        NotificationCard,
    },
    data() {
        return {
            notifications: NOTIFICATIONS,
        };
    },
    methods: {
        getNotificationData(item) {
            return {
                notification: item,
                hasPointer: true,
            };
        },
    },
};
</script>

<style lang="scss">
.home-page-notifications {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    overflow: hidden;

    @include respond-min($tablet) {
        row-gap: 16px;
    }

    &__notification-card {
        padding: 16px;
        border-radius: 24px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

        @include respond-min($tablet) {
            row-gap: 18px;
            padding: 18px 24px;
            box-shadow: 0 0 14px rgba(0, 0, 0, 0.15);
        }
    }

    .notification-card__main-content {
        padding: 16px;
        border-radius: 24px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

        @include respond-min($tablet) {
            row-gap: 18px;
            padding: 18px 24px;
        }
    }

    .notification-card__button {
        margin-top: 12px;
    }
}
</style>
