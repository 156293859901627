<template>
    <div class="home-page no-select">
        <AppHeader
            class="home-page__header container"
            v-bind="appHeaderData" />
        <p class="home-page__description container text-regular">
            Какой курс хотите пройти?
        </p>

        <slot />

        <HomePagePopup
            v-if="firstTimeUsing"
            cookieName="home-page:start-info"
            class="home-page__popup"
            @closePopup="closePopup" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppHeader from '@/components/AppHeader.vue';
import HomePagePopup from '@/components/home-page/HomePagePopup.vue';
import PreferenceKeeper from '@/utils/PreferenceKeeper/PreferenceKeeper';

export default {
    name: 'LayoutStartPage',
    components: {
        HomePagePopup,
        AppHeader,
    },
    data() {
        return {
            firstTimeUsing: false,
        };
    },
    computed: {
        ...mapGetters({
            user: 'structure/getUser',
        }),
        appHeaderData() {
            return {
                userName: this.user?.name,
            };
        },
    },
    methods: {
        closePopup() {
            this.firstTimeUsing = false;
            PreferenceKeeper.setItem('firstTimeUsing', false);
        },
    },
    created() {
        this.firstTimeUsing = false;
    },
};
</script>

<style lang="scss">
.home-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding:
        env(safe-area-inset-top)
        env(safe-area-inset-right)
        env(safe-area-inset-left);

    &__main-container {
        display: flex;
        flex-grow: 1;
    }

    &__description {
        margin-top: 14px;

        @include respond-min($tablet) {
            margin-top: 50px;
        }
    }
}
</style>
