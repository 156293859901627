import axios from '@/axios';
import { produce } from 'immer';
import { searchLessons, searchSubchapterById } from '@/utils/utils';

const apiUi = '/ui/structure';

export default {
    namespaced: true,
    state: {
        uiStructure: null,
        currentCourse: null,
        currentChapter: null,
        coursesByDepartment: null,
        currentLesson: null,
        user: null,
        notifications: null,
        currentSubchapter: null,
        userLikes: [],
    },
    mutations: {
        SET_UI_STRUCTURE(state, uiStructure) {
            state.uiStructure = uiStructure;
        },
        SET_USER_LIKES(state, userLikes) {
            state.userLikes = userLikes;
        },
        SET_NOTIFICATIONS(state, notifications) {
            state.notifications = notifications;
        },
        SET_COURSE(state, course) {
            state.currentCourse = course;
        },
        SET_CHAPTER(state, chapter) {
            state.currentChapter = chapter;
        },
        SET_SUBCHAPTER(state, subchapter) {
            state.currentSubchapter = subchapter;
        },
        SET_LESSON(state, lesson) {
            state.currentLesson = lesson;
        },
        SET_COURSES_BY_DEPARTMENT(state, courses) {
            state.coursesByDepartment = courses;
        },
    },
    actions: {
        async fetchUiStructure({ commit }) {
            const headers = {
                Accept: 'application/json',
                Authorization: 'Token token='.concat(JSON.parse(localStorage.getItem('auth'))),
            };
            let departments = [];
            let uiStructure = {};

            try {
                uiStructure = await axios.get(`${apiUi}`, {
                    headers,
                });
            } catch (e) {
                // удаляем старый или просроченный токен. Для редиректа на страницу логина
                localStorage.removeItem('auth');
            }

            try {
                departments = await axios.get('/departments', {
                    headers,
                });
            } catch (e) {
                console.log(e);
            }

            const updatedUiStructure = produce(uiStructure, draft => {
                draft.data.structure.departments = departments;
            });

            commit('SET_UI_STRUCTURE', updatedUiStructure);
        },
        async fetchUserLikes({ commit, state }) {
            try {
                const userId = state.uiStructure?.data?.user?.id;
                if (userId) {
                    const response = await axios.get(`/ui/likes?user_id=${userId}`, {
                        headers: {
                            Accept: 'application/json',
                            Authorization: 'Token token='.concat(JSON.parse(localStorage.getItem('auth'))),
                        },
                    });
                    const coursesLiked = response.data?.courses_ids || [];
                    const updatedCourses = state.uiStructure?.data?.structure?.courses.map(course => {
                        return {
                            ...course,
                            isLiked: coursesLiked.includes(course.id),
                        };
                    });

                    const updatedStructure = {
                        ...state.uiStructure?.data?.structure,
                        courses: updatedCourses,
                    };

                    commit('SET_USER_LIKES', coursesLiked);
                    commit('SET_UI_STRUCTURE', {
                        ...state.uiStructure,
                        data: {
                            ...state.uiStructure?.data,
                            structure: updatedStructure,
                        },
                    });
                }
            } catch (error) {
                console.error('Error in fetchUserLikes:', error);
            }
        },
        fetchNotifications({ state, commit }) {
            const notifications = state.uiStructure?.data?.notifications;
            commit('SET_NOTIFICATIONS', notifications);
        },
        fetchCourse({ state, commit }, courseId) {
            let course = {};
            course = state.uiStructure?.data?.structure?.courses.find(course => course.id === Number(courseId));
            course = { ...course, items: course.chapters };
            commit('SET_COURSE', course);
        },
        fetchCoursesByDepartment({ state, commit }, departmentId) {
            const courses = state.uiStructure?.data?.structure?.courses.filter(course => course.departmentId === Number(departmentId));
            commit('SET_COURSES_BY_DEPARTMENT', courses);
        },
        fetchChapter({ state, commit }, { courseId, chapterId, subchapterId, subsubchapterId, subsubsubchapterId, subsubsubsubchapterId, subsubsubsubsubchapterId }) {
            let chapter = {};
            const calcId = () => {
                if (subsubsubsubsubchapterId) {
                    return subsubsubsubsubchapterId;
                }
                if (subsubsubsubchapterId) {
                    return subsubsubsubchapterId;
                }
                if (subsubsubchapterId) {
                    return subsubsubchapterId;
                }
                if (subsubchapterId) {
                    return subsubchapterId;
                }

                return subchapterId;
            };
            const subchapterIdValue = calcId();
            const course = state.uiStructure?.data?.structure?.courses.find(course => course.id === Number(courseId));
            chapter = course?.chapters?.find(chapter => chapter.id === Number(chapterId));
            const lessons = chapter?.lessons ?? [];
            const subchapters = chapter?.subchapters ?? [];

            if (subchapterIdValue) {
                const results = searchSubchapterById(parseInt(subchapterIdValue, 10), subchapters);
                chapter = { ...chapter, title: results[0]?.title, items: [...results[0]?.lessons || [], ...results[0]?.subchapters || []] };
            } else {
                chapter = { ...chapter, items: [...lessons, ...subchapters] };
            }

            commit('SET_CHAPTER', chapter);
        },
        fetchCurrentSubchapter({ state, commit }, { courseId, chapterId, subchapterId }) {
            const course = state.uiStructure?.data?.structure?.courses.find(course => course.id === Number(courseId));
            const chapter = course?.chapters?.find(chapter => chapter.id === Number(chapterId));
            const subchapter = chapter?.subchapters.find(subchapter => subchapter.id === Number(subchapterId));

            commit('SET_SUBCHAPTER', subchapter);
        },
        fetchLesson({ state, commit }, { courseId, chapterId, lessonId }) {
            const course = state.uiStructure?.data?.structure?.courses.find(course => course.id === Number(courseId));
            const entryTest = course?.chapters.find(item => item?.type === 'entry_test');
            let lesson = '';

            if (entryTest?.type === 'entry_test') {
                lesson = entryTest;
            } else {
                const chapter = course?.chapters?.find(chapter => chapter.id === Number(chapterId));
                const chapterLesson = chapter?.lessons ?? [];
                const subchapters = chapter?.subchapters ?? [];
                let subchaptersLessons = [];

                if (subchapters?.length) {
                    subchaptersLessons = searchLessons(subchapters);
                }

                lesson = [...chapterLesson, ...subchaptersLessons]?.find(lesson => lesson.id === Number(lessonId));
            }
            commit('SET_LESSON', lesson);
        },
        async fetchLike({ _dispatch }, { itemId }) {
            try {
                await axios.post('/ui/like', { course_id: itemId });
            } catch (error) {
                console.error(error);
            }
        },
        async deleteLike({ _dispatch }, { itemId }) {
            try {
                await axios.delete('/ui/like', { data: { course_id: itemId } });
            } catch (error) {
                console.error(error);
            }
        },
        async fethSupport({ _dispatch }, { payload }) {
            console.log('vuex', payload);
            await axios({
                method: 'post',
                url: '/ui/support',
                data: payload,
            });
        },
    },
    getters: {
        getUiStructure: state => state.uiStructure,
        getUser: state => state.uiStructure?.data?.user,
        getNotifications: state => state.uiStructure?.data?.notifications,
        getCourses: state => state.uiStructure?.data?.structure?.courses,
        getDepartments: state => state.uiStructure?.data?.structure?.departments?.data,
        getCurrentCourse: state => state.currentCourse,
        getCoursesByDepartment: state => state.coursesByDepartment,
        getCurrentChapter: state => state.currentChapter,
        getCurrentSubchapter: state => state.currentSubchapter,
        getCurrentLesson: state => state.currentLesson,
        getCertificates: state => state.uiStructure?.data?.certificates,
    },
};
