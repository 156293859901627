<template>
    <article
        class="notification-card"
        v-bind="notificationCardData">
        <div
            class="notification-card__icon-wrapper"
            v-bind="iconWrapperData">
            <span
                v-if="notificationType === 'success'"
                class="notification-card__icon-container">
                <svg
                    class="notification-card__icon"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16 7.81429V8.50429C15.9991 10.1216 15.4754 11.6953 14.507 12.9907C13.5386 14.286 12.1775 15.2336 10.6265 15.6922C9.07557 16.1508 7.41794 16.0957 5.90085 15.5352C4.38376 14.9747 3.08849 13.9389 2.20822 12.5821C1.32795 11.2253 0.909843 9.62034 1.01626 8.00652C1.12267 6.39271 1.7479 4.85654 2.79871 3.6271C3.84951 2.39766 5.26959 1.54083 6.84714 1.1844C8.42469 0.827975 10.0752 0.991046 11.5525 1.64929"
                        stroke="#1D1D1D"
                        stroke-width="1.7"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path
                        d="M16 2.50439L8.5 10.0119L6.25 7.76189"
                        stroke="#1D1D1D"
                        stroke-width="1.7"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </span>

            <span
                v-else-if="notificationType === 'incomplete'"
                class="notification-card__icon-container">
                <svg
                    class="notification-card__icon"
                    viewBox="0 0 20 14"
                    fill="none">
                    <path
                        d="M9.78159 9.42835C9.6734 9.42835 9.56439 9.40671 9.46261 9.36183L0.482486 5.44922C0.18996 5.32179 -2.26971e-05 5.03248 -2.26971e-05 4.7143C-2.26971e-05 4.39613 0.189158 4.10682 0.482486 3.97939L9.46261 0.0666825C9.66617 -0.0222776 9.8994 -0.0222776 10.103 0.0666825L19.0815 3.97939C19.3741 4.10682 19.5624 4.39613 19.5624 4.7143C19.5624 5.03248 19.3749 5.32179 19.0815 5.44922L10.103 9.36183C10.0012 9.40591 9.89139 9.42835 9.78159 9.42835ZM2.80825 4.7143L9.78159 7.75258L16.7549 4.7143L9.78159 1.67602L2.80825 4.7143Z"
                        fill="#1D1D1D" />
                    <path
                        d="M9.78184 13.1371C7.35267 13.1371 4.9235 12.4085 2.7564 10.9507C2.5344 10.8008 2.40137 10.5524 2.40137 10.2855V6.01144C2.40137 5.56824 2.75962 5.21 3.20281 5.21C3.64601 5.21 4.00425 5.56824 4.00425 6.01144V9.8495C7.60273 12.0879 11.9609 12.0879 15.5594 9.8495V6.01144C15.5594 5.56824 15.9177 5.21 16.3609 5.21C16.8041 5.21 17.1623 5.56824 17.1623 6.01144V10.2855C17.1623 10.5524 17.0293 10.8016 16.8089 10.9507C14.6426 12.4077 12.211 13.1371 9.78184 13.1371Z"
                        fill="#1D1D1D" />
                    <path
                        d="M19.1986 10.9546C18.9782 10.9546 18.7978 10.7751 18.7978 10.5539V4.67615C18.7978 4.45496 18.9782 4.27543 19.1986 4.27543C19.4206 4.27543 19.5993 4.45496 19.5993 4.67615V10.5539C19.5993 10.7751 19.4214 10.9546 19.1986 10.9546Z"
                        fill="#1D1D1D" />
                    <path
                        d="M19.1989 11.3552C18.7557 11.3552 18.3975 10.997 18.3975 10.5538V7.74874C18.3975 7.30554 18.7557 6.94729 19.1989 6.94729C19.6421 6.94729 20.0003 7.30554 20.0003 7.74874V10.5538C20.0003 10.997 19.6421 11.3552 19.1989 11.3552Z"
                        fill="#1D1D1D" />
                </svg>
            </span>

            <span
                v-else-if="notificationType === 'certificate'"
                class="notification-card__icon-container">
                <svg
                    class="notification-card__icon"
                    viewBox="0 0 20 17"
                    fill="none">
                    <path
                        d="M5 1H1C1 5 3.66667 6 5 6V1Z"
                        stroke="black"
                        stroke-width="1.7"
                        stroke-linejoin="round" />
                    <path
                        d="M15 1H19C19 5 16.3333 6 15 6V1Z"
                        stroke="black"
                        stroke-width="1.7"
                        stroke-linejoin="round" />
                    <path
                        d="M5 1H15V6C15 8.76142 12.7614 11 10 11V11C7.23858 11 5 8.76142 5 6V1Z"
                        stroke="black"
                        stroke-width="1.7" />
                    <line
                        x1="7.85"
                        y1="13.15"
                        x2="12.15"
                        y2="13.15"
                        stroke="black"
                        stroke-width="1.7"
                        stroke-linecap="round" />
                    <line
                        x1="5.85"
                        y1="16.15"
                        x2="14.15"
                        y2="16.15"
                        stroke="black"
                        stroke-width="1.7"
                        stroke-linecap="round" />
                </svg>
            </span>

            <span
                v-else-if="notificationType === 'reminder'"
                class="notification-card__icon-container">
                <svg
                    class="notification-card__icon"
                    viewBox="0 0 18 18"
                    fill="none">
                    <circle
                        cx="9"
                        cy="9"
                        r="6.15"
                        stroke="black"
                        stroke-width="1.7" />
                    <line
                        x1="0.85"
                        y1="-0.85"
                        x2="3.34031"
                        y2="-0.85"
                        transform="matrix(-0.698164 0.715938 -0.698164 -0.715938 4.92529 13)"
                        stroke="black"
                        stroke-width="1.7"
                        stroke-linecap="round" />
                    <line
                        x1="0.85"
                        y1="-0.85"
                        x2="3.34031"
                        y2="-0.85"
                        transform="matrix(0.698164 0.715938 0.698164 -0.715938 13.0747 13)"
                        stroke="black"
                        stroke-width="1.7"
                        stroke-linecap="round" />
                    <path
                        d="M9 6L9 9.4965L11 11"
                        stroke="black"
                        stroke-width="1.7"
                        stroke-linecap="round" />
                    <path
                        d="M4 1L1 4"
                        stroke="black"
                        stroke-width="1.7"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path
                        d="M14 1L17 4"
                        stroke="black"
                        stroke-width="1.7"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </span>
        </div>

        <div class="notification-card__content-wrapper">
            <h4 class="notification-card__title text-bold">
                <slot name="title">
                    notificationTitle
                </slot>
            </h4>

            <p class="notification-card__description text-regular">
                <slot name="description">
                    notificationDescription
                </slot>
            </p>

            <p
                v-if="$slots.recievedAt"
                class="notification-card__recieved-date mini-text-regular clr-gray5">
                <slot name="recievedAt">
                    recievedAt
                </slot>
            </p>

            <AppButton
                v-if="$slots.buttonText"
                class="notification-card__button label-text-regular clr-blue">
                <slot name="buttonText">
                    buttonText
                </slot>
            </AppButton>
        </div>
    </article>
</template>

<script>
import AppButton from '@/components/AppButton.vue';

export default {
    name: 'NotificationCard',
    components: {
        AppButton,
    },
    props: {
        notification: {
            type: Object,
            default: () => ({}),
        },
        hasPointer: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        notificationCardData() {
            return {
                class: {
                    pointer: this.hasPointer,
                },
            };
        },
        notificationType() {
            return this.notification?.type;
        },
        isNotificationRead() {
            return this.notification?.read;
        },
        iconWrapperData() {
            return {
                class: this.isNotificationRead
                    ? 'bg-gray3'
                    : 'bg-orange0',
            };
        },
    },
};
</script>

<style lang="scss">
.notification-card {
    position: relative;
    display: flex;
    flex: 1;
    overflow: visible;
    padding-top: 12px;
    padding-bottom: 12px;
    column-gap: 16px;

    @include respond-min($tablet) {
        padding-top: 18px;
        padding-bottom: 18px;
        column-gap: 18px;
    }

    &__main-content {
        display: flex;
    }

    &__content-wrapper {
        display: flex;
        flex-direction: column;
    }

    &__description {
        @include respond-min($tablet) {
            margin-top: 4px;
        }
    }

    &__recieved-date {
        margin-top: 2px;

        @include respond-min($tablet) {
            margin-top: 8px;
        }
    }

    &__icon-wrapper {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;

        @include respond-min($tablet) {
            width: 48px;
            height: 48px;
        }
    }

    &__icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;

        @include respond-min($tablet) {
            width: 26px;
            height: 26px;
        }
    }

    &__icon {
        width: 100%;
        height: 100%;
    }

    &__delete-button {
        width: 100%;
        height: 100%;
    }

    &__button {
        align-self: flex-start;
        margin-top: 16px;
        padding: 0;
    }

    &.pointer:first-child {
        &::after {
            content: "";
            position: absolute;
            top: -8px;
            right: 68px;
            width: 32px;
            height: 32px;
            background-color: var(--white);
            clip-path:
                polygon(
                    50% 0,
                    100% 50%,
                    50% 100%,
                    0 50%
                );

            @include respond-min($tablet) {
                right: 39px;
            }
        }
    }
}
</style>
