/**
 * Возвращает случайный id
 * @returns string
 */
export const getRandomId = () => {
    const s4 = () => Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    const result = `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;

    return result;
};

/**
 * Возвращает слово в правильном склонении
 * pluralize(1, ['балл', 'балла', 'баллов']) // 1 балл
 * pluralize(2, ['балл', 'балла', 'баллов']) // 2 балла
 * pluralize(5, ['балл', 'балла', 'баллов']) // 5 баллов
 * @param {number} number
 * @param {array} titles
 * @returns string
 */
export const pluralize = (number, titles) => {
    const numberAbs = Math.abs(number);

    return titles[
        (numberAbs % 100 > 4 && numberAbs % 100 < 20)
            ? 2
            : [2, 0, 1, 1, 1, 2][(numberAbs % 10 < 5) ? numberAbs % 10 : 5]
    ];
};

/**
 * Возвращает массив с subchapter который подходит под условие value
 * value - id искомого subchapter
 * items - массив subchapter
 */
export const searchSubchapterById = (value, items) => {
    return items.reduce((acc, curr) => {
        if (curr.id === value) {
            acc.push(curr);
        }
        if (curr?.subchapters && curr?.subchapters.length > 0) {
            acc = acc.concat(searchSubchapterById(value, curr.subchapters));
        }

        return acc;
    }, []);
};

/**
 * Возвращает массив с lessons которые принадлежат подглавам (items) Главы
 * items - массив subchapters
 */
export const searchLessons = (items) => {
    return items.reduce((acc, curr) => {
        if (curr.lessons?.length) {
            acc.push(...curr?.lessons);
        }
        if (curr?.subchapters && curr?.subchapters.length > 0) {
            acc = acc.concat(searchLessons(curr.subchapters));
        }

        return acc;
    }, []);
};

/**
 * Генерирует префикс для subchapter
 * @param {number} value
 * @returns string
 */

export const generatePrefix = (value) => 'sub'.repeat(value);

/**
 * Calculates the name and parameter name for a given set of subchapter IDs.
 *
 * @param {Object} options - The options object containing the subchapter IDs.
 * @param {string} options.subchapterId - The ID of the subchapter.
 * @param {string} options.subsubchapterId - The ID of the subsubchapter.
 * @param {string} options.subsubsubchapterId - The ID of the subsubsubchapter.
 * @param {string} options.subsubsubsubchapterId - The ID of the subsubsubsubchapter.
 * @param {string} options.subsubsubsubsubchapterId - The ID of the subsubsubsubsubchapter.
 * @return {Object} The name and parameter name for the given subchapter IDs.
 */
export const calculateParamsName = ({ subchapterId, subsubchapterId, subsubsubchapterId, subsubsubsubchapterId, subsubsubsubsubchapterId }) => {
    if (subsubsubsubsubchapterId) {
        return {
            name: 'subsubsubsubsubsubchapter',
            paramName: 'subsubsubsubsubsubchapterId',
        };
    }
    if (subsubsubsubchapterId) {
        return {
            name: 'subsubsubsubsubchapter',
            paramName: 'subsubsubsubsubchapterId',
        };
    }
    if (subsubsubchapterId) {
        return {
            name: 'subsubsubsubchapter',
            paramName: 'subsubsubsubchapterId',
        };
    }
    if (subsubchapterId) {
        return {
            name: 'subsubsubchapter',
            paramName: 'subsubsubchapterId',
        };
    }
    if (subchapterId) {
        return {
            name: 'subsubchapter',
            paramName: 'subsubchapterId',
        };
    }
    return {
        name: 'subchapter',
        paramName: 'subchapterId',
    };
};

export const getDomain = () => {
    if (process.env.NODE_ENV === 'development') {
        return 'https://taxi.edge.redo.moscow/';
    }

    return `${window.location.origin}/`;
};
