<template>
    <LayoutStartPage>
        <div class="container home-page__description">
            <AppButton
                class="page-layout__back-button button-back-department"
                @click="$router.push({
                    name: '/',
                })">
                <span class="page-layout__back-button-icon-wrapper">
                    <svg
                        class="page-layout__back-button-icon"
                        viewBox="0 0 15 26"
                        fill="none">
                        <path
                            d="M13.0015 1.85715L1.00146 13.4286L13.0015 25"
                            stroke="#232323"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </span>
                <span class="page-layout__back-button-text label-text-regular clr-black">Вернуться назад</span>
            </AppButton>
        </div>
        <main class="home-page__main-container">
            <div class="home-page__description container text-regular"
                 v-if="!hasLengthCourses">
                В данном разделе нет курсов
            </div>
            <div class="table">
                <div
                    :key="i"
                    v-for="(course, i) in courses"
                    class="table__cell">
                    <CourseTile
                        :key="i"
                        v-bind="getCourseSlideData(course, i)"/>
                </div>
            </div>
        </main>
    </LayoutStartPage>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CourseTile from '@/components/course-slider/CourseTile.vue';
import LayoutStartPage from '@/components/LayoutStartPage.vue';
import AppButton from '@/components/AppButton.vue';

export default {
    name: 'DepartmentPage',
    components: {
        AppButton,
        LayoutStartPage,
        CourseTile,
    },
    computed: {
        ...mapGetters({
            courses: 'structure/getCoursesByDepartment',
        }),
        hasLengthCourses() {
            return this.courses?.length;
        },
        courseSliderData() {
            return {
                sliderOptions: {
                    speed: 300,
                    spaceBetween: 16,
                    roundLength: true,
                    breakpoints: {
                        768: {
                            spaceBetween: 20,
                        },
                    },
                },
                sliderNavigationClass: 'home-page__slider-navigation',
                sliderWrapperClass: 'home-page__slides-wrapper',
            };
        },
    },
    methods: {
        ...mapActions({
            fetchCoursesByDepartment: 'structure/fetchCoursesByDepartment',
        }),
        getCourseSlideData(course, index) {
            return {
                courseData: course,
                slideIndex: index,
            };
        },

        fetchCurrentCourses() {
            const { departmentId } = this.$route.params;

            this.fetchCoursesByDepartment(departmentId);
        },
    },
    created() {
        this.fetchCurrentCourses();
    },
};
</script>

<style lang="scss">
.button-back-department {
    display: flex;
    align-items: center;
}

.home-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding:
        env(safe-area-inset-top)
        env(safe-area-inset-right)
        env(safe-area-inset-left);

    &__description {
        margin-top: 14px;

        @include respond-min($tablet) {
            margin-top: 50px;
        }
    }

    &__main-container {
        display: flex;
        flex-grow: 1;
    }

    &__slider {
        display: flex;
        flex-direction: column;
        padding-top: 24px;
        padding-bottom: 36px;

        @include respond-min($tablet) {
            padding-top: 36px;
            padding-bottom: 56px;
        }
    }

    &__slider-navigation {
        flex-shrink: 0;
        margin-top: 20px;

        @include respond-min($tablet) {
            margin-top: 36px;
        }

        .swiper-pagination {
            position: static;
            display: flex;
            justify-content: center;
            padding: 8px 10px;
            column-gap: 14px;

            @include respond-min($tablet) {
                column-gap: 18px;
            }

            .swiper-pagination-bullet {
                width: 6px;
                height: 6px;
                margin: 0;
                border-radius: 16px;
                background-color: var(--orange0);
                opacity: 1;
                transition-timing-function: ease-in-out;
                transition-duration: 0.3s;
                transition-property: width, background-color;

                @include respond-min($tablet) {
                    width: 8px;
                    height: 8px;
                }
            }

            .swiper-pagination-bullet-active {
                width: 16px;
                background-color: var(--black);

                @include respond-min($tablet) {
                    width: 24px;
                }
            }
        }
    }
}

.page-layout__back-button {
    display: flex;
    align-items: center;
}

.page-layout__back-button-icon-wrapper {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    width: 12px;
    height: 24px;
    margin-right: 10px;

    @include respond-min($tablet) {
        width: 10px;
        height: 18px;
        margin-right: 16px;
    }
}

.page-layout__back-button-icon {
    width: 100%;
    height: 100%;
}
</style>
