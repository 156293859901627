<template>
    <AppFullscreenPopup
        cookieName="home-page:start-info"
        class="home-page-popup"
        @button:click="popupButtonClickHandler">
        <div class="home-page-popup__content-wrapper">
            <div class="home-page-popup__image-wrapper" />
            <h2 class="home-page-popup__title h3-extra-bold">
                Поздравляем<br> с началом обучения!
            </h2>
            <p class="home-page-popup__description text-regular">
                Для работы таксистом профессиональное вождение – важное условие, но не единственное. Также важно знать город, понятно выражать свои мысли, понимать, что нужно пассажиру.<br>
                Вы здесь и, значит, готовы к профессиональному росту. У вас есть возможность узнать много нового и стать лучшим в своей профессии.
            </p>
        </div>
        <AppButton
            class="home-page-popup__button button"
            @click="popupButtonClickHandler">
            Приятного обучения!
        </AppButton>
    </AppFullscreenPopup>
</template>

<script>
import AppButton from '@/components/AppButton.vue';
import AppFullscreenPopup from '@/components/AppFullscreenPopup.vue';

export default {
    name: 'HomePagePopup',
    components: {
        AppButton,
        AppFullscreenPopup,
    },
    methods: {
        popupButtonClickHandler() {
            this.$emit('closePopup');
        },
    },
};
</script>

<style lang="scss">
.home-page-popup {
    padding: 82px 24px;

    &__content-wrapper {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__image-wrapper {
        flex-shrink: 0;
        height: 198px;
        background-image: url("~@/assets/img/home-page/start-info.svg");
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    &__title {
        margin-top: 30px;
    }

    &__description {
        margin-top: 12px;

        @include respond-min($tablet) {
            margin-top: 24px;
        }
    }

    &__button {
        align-self: center;
        width: 100%;
        margin-top: 30px;
    }
}
</style>
