<template>
    <div
        class="app-image-resolver"
        :style="{ backgroundImage: `url(${imageUrl})`, backgroundPosition: `${calcPosition}` }" />
</template>

<script>
export default {
    name: 'AppImageResolver',
    components: {
    },
    props: {
        // Имя папки. При отсутствии имени папки изображения подтягиваются из @/assets/img
        folderName: {
            type: String,
            default: '',
        },
        // Имя файла изображения. Обязательный параметр.
        imageName: {
            type: [String, Number],
            required: true,
        },
        // Расширение файла изображения
        imageExt: {
            type: String,
            default: 'svg',
        },
        imagePath: {
            type: String,
            default: 'png',
        },
        position: {
            type: String,
            default: 'left',
            required: false,
        },
    },
    computed: {
        computedFolderName() {
            return this.folderName.length > 0
                ? `/${this.folderName}`
                : '';
        },
        calcPosition() {
            return this.position;
        },
        imageUrl() {
            if (this.imagePath) {
                return this.imagePath;
            }

            if (!this.imageName) {
                return '';
            }

            const url = require(`@/assets/img${this.computedFolderName}/${this.imageName}.${this.imageExt}`);
            return url;
        },
    },
};
</script>

<style lang="scss">
.app-image-resolver {
    display: flex;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
</style>
