<template>
    <LayoutStartPage>
        <div class="table" v-if="isExistList">
            <div
                :key="i"
                v-for="(department, i) in departments"
                class="table__cell">
                <h2 class="h2-extra-bold departament-title">
                    {{ department.title }}
                </h2>
                <div class="table__wrapper course-slide__image-container">
                    <AppImageResolver
                        folderName="home-page"
                        :imageName="department?.image?.title"
                        :imagePath="department?.image?.src"
                        position="center"
                        class="course-slide__image" />
                </div>
                <AppButton
                    class="button departament-button"
                    tag="a"
                    v-bind="getButtonData(department)">
                    Пройти курс
                </AppButton>
            </div>
        </div>
        <div class="table" v-else>
            Список департаментов пуст
        </div>
    </LayoutStartPage>
</template>

<script>
import { mapGetters } from 'vuex';
import LayoutStartPage from '@/components/LayoutStartPage.vue';
import AppButton from '@/components/AppButton.vue';
import AppImageResolver from '@/components/AppImageResolver.vue';

export default {
    name: 'StartPage',
    components: {
        AppImageResolver,
        AppButton,
        LayoutStartPage,
    },
    computed: {
        ...mapGetters({
            departments: 'structure/getDepartments',
        }),
        isExistList() {
            return !!this.departments?.length;
        },
    },
    methods: {
        getButtonData(item) {
            return {
                to: {
                    name: 'department',
                    params: {
                        departmentId: item?.id,
                    },
                },
            };
        },
    },
};
</script>

<style lang="scss">
.table {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 48px;
    width: 100%;
    padding: 48px;

    @include respond-max($desktop) {
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        padding: 40px;
    }

    @include respond-max($tablet) {
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        padding: 25px 10px 10px;
    }

    &__cell {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 30px 24px;
        border-radius: 24px;
        background-color: var(--white);
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

        @include respond-max($tablet) {
            padding: 15px 12px;
        }
    }

    &__wrapper {
        width: 100%;
        padding: 15px 0;
    }
}

a.departament-button {
    @include respond-max($tablet) {
        padding: 9px 18px;
        font-size: 16px;
        text-align: center;
    }
}

h2.departament-title {
    @include respond-max($tablet) {
        font-size: 16px;
    }
}
</style>
