import Vue from 'vue';
import VueRouter from 'vue-router';
import StartPage from '@/pages/StartPage.vue';
import store from '@/store';
import DepartmentPage from '@/pages/DepartmentPage.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: StartPage,
        meta: { requiresAuth: true },
    },
    {
        path: '/department:departmentId',
        name: 'department',
        component: DepartmentPage,
        meta: { requiresAuth: true },
    },
    {
        path: '/course:courseId',
        name: 'course',
        component: () => import('@/pages/CoursePage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/course:courseId/chapter:chapterId',
        name: 'chapter',
        component: () => import('@/pages/ChapterPageNew.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/course:courseId/chapter:chapterId/subchapter:subchapterId',
        name: 'subchapter',
        component: () => import('@/pages/ChapterPageNew.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/course:courseId/chapter:chapterId/subchapter:subchapterId/subsubchapter:subsubchapterId',
        name: 'subsubchapter',
        component: () => import('@/pages/ChapterPageNew.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/course:courseId/chapter:chapterId/subchapter:subchapterId/subsubchapter:subsubchapterId/subsubsubchapter:subsubsubchapterId',
        name: 'subsubsubchapter',
        component: () => import('@/pages/ChapterPageNew.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/course:courseId/chapter:chapterId/subchapter:subchapterId/subsubchapter:subsubchapterId/subsubsubchapter:subsubsubchapterId/subsubsubsubchapter:subsubsubsubchapterId',
        name: 'subsubsubsubchapter',
        component: () => import('@/pages/ChapterPageNew.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/course:courseId/chapter:chapterId/subchapter:subchapterId/subsubchapter:subsubchapterId/subsubsubchapter:subsubsubchapterId/subsubsubsubchapter:subsubsubsubchapterId/subsubsubsubsubchapter:subsubsubsubsubchapterId',
        name: 'subsubsubsubsubchapter',
        component: () => import('@/pages/ChapterPageNew.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/course:courseId/entry_test:lessonId',
        name: 'entry_test',
        component: () => import('@/pages/LessonPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/course:courseId/chapter:chapterId/lesson:lessonId',
        name: 'lesson',
        component: () => import('@/pages/LessonPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/course:courseId/chapter:chapterId/subchapter:subchapterId/lesson:lessonId',
        name: 'sublesson',
        component: () => import('@/pages/LessonPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/course:courseId/chapter:chapterId/subchapter:subchapterId/subsubchapter:subsubchapterId/lesson:lessonId',
        name: 'subsublesson',
        component: () => import('@/pages/LessonPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/course:courseId/chapter:chapterId/subchapter:subchapterId/subsubchapter:subsubchapterId/subsubsubchapter:subsubsubchapterId/lesson:lessonId',
        name: 'subsubsublesson',
        component: () => import('@/pages/LessonPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/course:courseId/chapter:chapterId/subchapter:subchapterId/subsubchapter:subsubchapterId/subsubsubchapter:subsubsubchapterId/subsubsubsubchapter:subsubsubsubchapterId/lesson:lessonId',
        name: 'subsubsubsublesson',
        component: () => import('@/pages/LessonPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/course:courseId/chapter:chapterId/subchapter:subchapterId/subsubchapter:subsubchapterId/subsubsubchapter:subsubsubchapterId/subsubsubsubchapter:subsubsubsubchapterId/subsubsubsubsubchapter:subsubsubsubsubchapterId/lesson:lessonId',
        name: 'subsubsubsubsublesson',
        component: () => import('@/pages/LessonPage.vue'),
        meta: { requiresAuth: true },
    },
    // {
    //     path: '/course:courseId/chapter:chapterId/subchapter:subchapterId/lesson:lessonId',
    //     name: 'lessonSubchapters',
    //     component: () => import('@/pages/LessonPageSubchapters.vue'),
    //     meta: { requiresAuth: true },
    // },
    {
        path: '/auth',
        name: 'auth',
        component: () => import('@/pages/AuthPage.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/pages/RegisterPage.vue'),
        meta: { requiresAuth: false },
    },
    // {
    //     path: '/intro',
    //     name: 'intro',
    //     component: () => import('@/pages/IntroPage.vue'),
    //     meta: { requiresAuth: true },
    // },
    // {
    //     path: '/notifications',
    //     name: 'notifications',
    //     component: () => import('@/pages/NotificationsPage.vue'),
    //     meta: { requiresAuth: true },
    // },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/pages/ProfilePage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/certificates',
        name: 'certificates',
        component: () => import('@/pages/CertificatesPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/pages/AboutPage.vue'),
        meta: { requiresAuth: true },
    },
    // {
    //     path: '/how',
    //     name: 'how',
    //     component: () => import('@/pages/HowPage.vue'),
    //     meta: { requiresAuth: true },
    // },
    {
        path: '/license',
        name: 'license',
        component: () => import('@/pages/LicensePage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/pages/PrivacyPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import('@/pages/TermsPage.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '*',
        name: 'error',
        component: () => import('@/pages/ErrorPage.vue'),
        meta: { requiresAuth: true },
    },
];

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

const updateUiStructure = async() => {
    await store._actions['structure/fetchUiStructure'][0]()
        .catch(() => {
            console.error('Ui structure not found');
        });
    await store._actions['structure/fetchUserLikes'][0]()
        .catch(() => {
            console.error('fetchUserLikes not found');
        });
};
const parseParams = () => {
    const params = new URLSearchParams(window.location.search);
    const obj = {};

    for (const key of params.keys()) {
        obj[key] = params.get(key);
    }

    return obj;
};

const updateAuthToken = () => {
    const params = parseParams();

    if (params?.token) {
        localStorage.setItem('auth', JSON.stringify(params.token));
    }
};

router.beforeResolve(async(to, _from, next) => {
    const routeNeedAuth = to.matched.some(route => route.meta.requiresAuth);
    const userLoggedIn = !!localStorage.getItem('auth');
    const uiStructureFetched = store.getters['structure/getUiStructure'] !== null;

    //  Проверка на токен в урле и выставление его в качестве рабочего токена
    updateAuthToken();

    localStorage.setItem('configRoute', JSON.stringify(to.params));

    if (userLoggedIn && !uiStructureFetched) {
        await updateUiStructure();
    }

    if (routeNeedAuth) {
        // еще раз проверяем есть ли токен. Так как при не удачном запросе старый токен удаляем в structure.js
        const userLoggedIn = !!localStorage.getItem('auth');

        userLoggedIn
            ? next()
            : next({
                name: 'auth',
            });
    } else {
        // еще раз проверяем есть ли токен. Так как при не удачном запросе старый токен удаляем в structure.js
        const userLoggedIn = !!localStorage.getItem('auth');

        userLoggedIn
            ? next({
                name: 'home',
            })
            : next();
    }
});

export default router;
