import axios from 'axios';

let baseURL = '/api/v1';

if (process.env.NODE_ENV === 'development') {
    baseURL = 'https://taxi.edge.redo.moscow/api/v1';
}

export default axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        Authorization: 'Token token='.concat(JSON.parse(localStorage.getItem('auth'))),
    },
});
